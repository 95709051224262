import React from "react";
import {graphql} from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Button } from "../../components/Core";
import PostDetails from "../../sections/blog/PostDetails";
import { navigate } from "gatsby";
import {getProperty} from "../../utils/helperFn";
import YouTubeVideo from "../../components/YouTubeVideo/YouTubeVideo";
import moment from "moment";
import Seo from "../../components/Seo";
import styled from "styled-components";

const VideoWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 600px;
    @media (max-width: 991px){
        height: 400px;
    }
`;

const InsightDetails = (props) => {

    const article = getProperty(props.data, 'strapiInsights')

    const videoUrl = getProperty(article, 'video_url');
    let videoId = null;
    if (videoUrl) {
        if (videoUrl.includes('watch?v=')) {
            videoId = videoUrl.split("watch?v=")[1]
        } else {
            videoId = videoUrl.split("be/")[1]
            videoId = videoId.split("?si=")[0]
        }
    }

    return (
        <>
            <Seo page={article.slug}/>
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5"/>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="12">
                                <Title variant="news">
                                    {getProperty(article, 'title')}
                                </Title>
                                <Box className="d-flex justify-content-center">
                                    <Text mr={3}>
                                        {moment(article.backdate).format('ll')}
                                    </Text>
                                </Box>
                            </Col>
                        </Row>
                        {
                            videoId && (
                                <VideoWrapper>
                                    <YouTubeVideo videoId={videoId} />
                                </VideoWrapper>
                            )
                        }
                    </Container>
                </Section>
                <Section className="pb-5">
                    <Container>
                        <Row>
                            <Col sm="12" md="10" className="mb-5 mx-auto post-details">
                                <PostDetails content={article.content} image={article.image_content ? article.image_content?.childImageSharp?.fluid : null}/>
                            </Col>
                            <Col sm="12" md="10" className="mb-5 mx-auto text-center">
                                <Button onClick={() => navigate('/insights')}>
                                    Back to Insights
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                {/*<Comments />*/}
            </PageWrapper>
        </>
    );
};

export const data = graphql`
    query getInsightsOnSlug($slug: String) {
      strapiInsights(slug: {eq: $slug}) {
        id
        backdate
        video_url
        content
        title
        slug
        image_content {
           childImageSharp {
             fluid(maxWidth: 1000) {
               ...GatsbyImageSharpFluid
             }
           }
        }
        image_featured {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
         }
      }
    }`


export default InsightDetails;
